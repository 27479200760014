<template>
  <div class="main-wrapper">
    <layoutheader></layoutheader>
    <breadcrumb1
      :title="$t('beneficiarieslist')"
      :text="$t('home')"
      :text1="$t('beneficiarieslist')"
    />

    <div class="content">
      <div class="container">
        <div class="row">
          <!-- Profile Sidebar -->
          <div class="col-lg-4 col-xl-3 theiaStickySidebar">
            <profilesidebar></profilesidebar>
          </div>
          <div class="col-lg-8 col-xl-9">
            <div class="dashboard-header">
              <h3 class="d-flex justify-content-between w-100">
                <span>
                  {{ $t("beneficiarieslist") }}
                </span>
                <button
                  type="button"
                  class="btn btn-primary py-2 px-4"
                  @click="$router.push('add-relative')"
                >
                  {{ $t("addBeneficiary") }}
                  <span class="mx-1"> + </span>
                </button>
              </h3>
            </div>
            <div class="d-flex justify-content-center py-5" v-if="lodaing">
              <div
                class="spinner-border"
                style="width: 3rem; height: 3rem"
                role="status"
              ></div>
            </div>
            <div class="" v-else-if="!lodaing && patientFamilyList.length">
              <div class="custom-table">
                <div class="table-responsive">
                  <table class="table table-center mb-0">
                    <thead>
                      <tr>
                        <th>{{ $t("register.name") }}</th>
                        <th>{{ $t("register.relative_degree") }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item in patientFamilyList" :key="item.id">
                        <td>
                          {{ item.name }}
                        </td>
                        <td>
                          {{ item.relative_degree }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div
              v-else-if="!lodaing && !patientFamilyList.length"
              class="p-5 text-center"
            >
              {{ $t("noSearchResult") }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <footerindex></footerindex>
  </div>
</template>

<script>
import { getPatientFamily } from "@/api/patient";

export default {
  name: "addRelative",
  components: {},
  data() {
    return {
      lodaing: false,
      patientFamilyList: [],
    };
  },
  created() {
    this.getPatientFamilyList();
  },
  methods: {
    async getPatientFamilyList() {
      this.loading = true;
      try {
        const res = await getPatientFamily();
        if (res && res.data) {
          this.patientFamilyList = res.data;
        }
      } catch (error) {
        console.error("Error fetching patient family list:", error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
