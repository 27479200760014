<template>
  <div class="">
    <section class="work-section" v-if="!isLoggedIn">
      <div class="container">
        <div class="work-details">
          <div class="section-header-one aos" data-aos="fade-up">
            <h2 class="section-title">{{ $t("registerDoctorPtient") }}</h2>
          </div>
          <div class="row">
            <div class="col-lg-6 col-md-6 aos" data-aos="fade-up">
              <router-link to="/doctor-register">
                <div class="work-info d-flex align-items-center">
                  <div class="work-icon">
                    <span
                      ><img src="@/assets/img/icons/work-01.svg" alt="Image"
                    /></span>
                  </div>
                  <div class="work-content">
                    <h5>{{ $t("register.doctorRegister") }}</h5>
                  </div>
                </div>
              </router-link>
            </div>
            <div class="col-lg-6 col-md-6 aos" data-aos="fade-up">
              <router-link to="/patient-register">
                <div class="work-info d-flex align-items-center">
                  <div class="work-icon">
                    <span
                      ><img src="@/assets/img/icons/work-01.svg" alt="Image"
                    /></span>
                  </div>
                  <div class="work-content">
                    <h5>{{ $t("register.patientRegister") }}</h5>
                  </div>
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="statistics-section mt-5">
      <div class="container">
        <div class="row">
          <div class="col-md-12 aos" data-aos="fade-up">
            <div class="section-header-one text-center">
              <h2 class="section-title">{{ $t("statistics") }}</h2>
            </div>
          </div>
        </div>
        <div class="row" v-if="statisticsList">
          <div class="col-md-4 aos" data-aos="fade-up">
            <div class="statistic-card text-center">
              <h3>
                <vue3-autocounter
                  ref="counter"
                  :startAmount="0"
                  :endAmount="statisticsList.doctors"
                  :duration="3"
                  :autoinit="true"
                  separator=","
                />
              </h3>
              <p>{{ $t("doctors") }}</p>
            </div>
          </div>
          <div class="col-md-4 aos" data-aos="fade-up">
            <div class="statistic-card text-center">
              <h3>
                <vue3-autocounter
                  ref="counter"
                  :startAmount="0"
                  :endAmount="statisticsList.patients"
                  :duration="3"
                  :autoinit="true"
                  separator=","
                />
              </h3>
              <p>{{ $t("patients") }}</p>
            </div>
          </div>
          <div class="col-md-4 aos" data-aos="fade-up">
            <div class="statistic-card text-center">
              <h3>
                <vue3-autocounter
                  ref="counter"
                  :startAmount="0"
                  :endAmount="statisticsList.appointments_done"
                  :duration="3"
                  :autoinit="true"
                  separator=","
                />
              </h3>
              <p>{{ $t("appointmentsDone") }}</p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="articles-section">
      <div class="container">
        <div class="row">
          <div class="col-md-12 aos" data-aos="fade-up">
            <div class="section-header-one text-center">
              <h2 class="section-title">{{ $t("latestArticles") }}</h2>
            </div>
          </div>
        </div>
        <div class="row" v-if="blogsList?.length">
          <div
            class="col-lg-6 col-md-6 d-flex aos"
            data-aos="fade-up"
            v-for="item in blogsList.slice(0, 4)"
            :key="item.id"
          >
            <div class="articles-grid w-100">
              <div class="articles-info">
                <div class="articles-left">
                  <router-link :to="`/blog-details?blogId=${item.id}`">
                    <div class="articles-img">
                      <img
                        class="img-fluid"
                        :src="item.image"
                        :alt="item.lang"
                      />
                    </div>
                  </router-link>
                </div>
                <div class="articles-right">
                  <div class="articles-content">
                    <ul class="articles-list nav">
                      <li>
                        <i class="feather-user"></i>{{ item.category.lang }}
                      </li>
                      <li><i class="feather-calendar"></i> {{ item.date }}</li>
                    </ul>
                    <h4 class="mb-3">
                      <router-link :to="`/blog-details?blogId=${item.id}`">{{
                        item.lang
                      }}</router-link>
                    </h4>
                    <p
                      v-if="item.sub_description"
                      v-html="item.sub_description"
                      style="height: 45px"
                    ></p>

                    <router-link
                      :to="`/blog-details?blogId=${item.id}`"
                      class="btn"
                      >{{ $t("details") }}</router-link
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import AOS from "aos";
import "aos/dist/aos.css";
import IndexArticles from "@/assets/json/indexarticles.json";
import { mapGetters } from "vuex";
import { blogsSearch } from "@/api/general";
import { statistics } from "@/api/lookups";
import Vue3autocounter from "vue3-autocounter";

export default {
  components: {
    Vue3autocounter,
  },
  data() {
    return {
      IndexArticles: IndexArticles,
      loading: false,
      blogsList: [],
      loading: false,
      statisticsList: [],
    };
  },
  computed: {
    ...mapGetters(["isLoggedIn"]),
  },
  created() {
    this.getDataList();
    this.getStatistics();
  },
  mounted() {
    this.$nextTick(() => {
      AOS.init();
    });
  },
  methods: {
    async getDataList() {
      this.loading = true;

      try {
        const response = await blogsSearch();

        if (response) {
          this.blogsList = response.data.data;
        }
      } catch (error) {
        console.error("Error fetching roles list:", error);
      } finally {
        this.loading = false;
      }
    },
    async getStatistics() {
      const response = await statistics();
      if (response) {
        console.log("response", response);
        this.statisticsList = response.data;
      }
    },
  },
};
</script>
<style scoped>
.section-header-one .section-title::before {
  display: none;
}

.statistic-card {
  padding: 20px;
  margin: 15px 0;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

.statistic-card h3 {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.statistic-card p {
  font-size: 1.1rem;
  color: #666;
  margin: 0;
}
</style>
