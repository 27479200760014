import Cookies from "js-cookie";

export function setToken(token) {
  return Cookies.set("Token-Key", token);
}

export function getToken() {
  return Cookies.get("Token-Key");
}

export function removeToken() {
  return Cookies.remove("Token-Key");
}

export function setUserData(data) {
  return Cookies.set("User-Data", data);
}

export function getUserData() {
  return Cookies.get("User-Data");
}

export function removeUserData() {
  return Cookies.remove("User-Data");
}

export function setForgotMail(data) {
  return Cookies.set("forgot-mail", data);
}

export function getForgotMail() {
  return Cookies.get("forgot-mail");
}

export function removeForgotMail() {
  return Cookies.remove("forgot-mail");
}

export function setCompleteLogin(data) {
  return Cookies.set("complete-login", data);
}

export function getCompleteLogin() {
  return Cookies.get("complete-login");
}

export function removeCompleteLogin() {
  return Cookies.remove("complete-login");
}
